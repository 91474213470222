@import '~bootstrap/scss/bootstrap.scss';
@import '@fortawesome/fontawesome-free/css/all.css';

$navbar-bg-color: #67312d !important;
$navbar-text-color: #fff;
.navbar {
  background-color: $navbar-bg-color;
  color: $navbar-text-color;
  padding-left: 20px;
}

.navbar-nav a {
  color: #fff !important;
}

.navbar-brand {
  color: #fff;
}

.list-group-item {
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  margin-bottom: 10px;
}

.todo-item {
  margin-bottom: 0px;
}

.todo-list-container {
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 100px;
}

.todo-list {
  padding-left: 0;
  list-style: none;
}

.login-form-container {
  max-width: 400px;
  margin: auto;
  margin-top: 100px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 5px;
      font-weight: 500;
    }

    input {
      margin-bottom: 15px;
    }
  }

  button[type="submit"] {
    margin-top: 10px;
  }
}

.container-fluid {
  max-width: 1200px;
  margin: 0 auto;
}
